<template>
  <div class="container">
    <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>
    <div class="nf-tabs">
      <div class="nf-tabs-nav">
        <router-link
          v-for="(nav, index) in tabsNav"
          :key="index"
          class="nf-tabs-nav-label"
          :class="{'is-active': activeNav === index}"
          :to="`${nav.url}`"
        > 
          <i  class="icon" :class="nav.icon"></i>
          <span>{{nav.name}}</span>
        </router-link>
      </div>
      <div class="nf-tabs-content">
        <div class="container">
            <div class="nf-tabs-filter-form">
              <el-form :inline="true" class="filter-form" :model="rulesForm" :rules="rules" ref="rulesForm">
                <el-form-item prop="year" label="年份">
                  <el-date-picker v-model="rulesForm.year" value-format="yyyy" type="year" placeholder="请选择"></el-date-picker>
                </el-form-item>
                <el-form-item label="省份">
                  <el-select class="form-row" v-model="rulesForm.registerProvinceId" placeholder="请选择">
                    <el-option :label="'全部'" :value="''"></el-option>
                    <el-option :label="item.name" :value="item.id" v-for="(item,index) in provinceList" :key="index"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="submit">查询</el-button>
                  <el-button type="primary" @click="downLoadFile">导出数据</el-button>
                </el-form-item>
              </el-form>
            </div>
            <div class="nf-tabs-content">
              <div class="nf-tabs-cont">
                <iframe class="iframe-demo" :src="`https://view.officeapps.live.com/op/view.aspx?src=${filePath}?${dateYaer}`" frameborder="0"></iframe>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import nfBreadcrumb from "@/component/breadcrumb/breadcrumb";
import { baseUrl } from '@/utils/config';
import { downlodFile } from '@/utils/utils';// 解析excel插件

export default {
  components: {
    nfBreadcrumb
  },

  data() {
    return {
      centerDialogVisible: false,
      centerDialogVisiblese: false,
      activeNav: 1,
      tabsNav: [
        {
          name: "登记信息管理",
          icon: "icon-processing",
          url: "/nanfaninformatManage"
        },
        {
          name: "南繁备案统计",
          icon: "icon-draft",
          url: "/nanfanStatistics"
        }
      ],
      breadList: [
        {
          name: "登记管理"
        }
      ],
      backlog: '', // 待办管理数量
      param: {
        circulateType: 2,
        documentStatus: 2,
        emergencyLevel: '',
        endDate: '',
        keyWord: '',
        pageNum: 1,
        pageSize: 10,
        startDate: ''
      },
      rulesForm: {
        registerProvinceId: '',
        year: '' // 年份
      },
      rules: {
        registerProvinceId: [
          { required: true, message: '请选择省份', trigger: 'blur' }
        ],
        year: [
          { required: true, message: '请选择年份', trigger: 'blur' }
        ]
      },
      provinceList: [], // 省份list
      filePath: '', // excel文件地址
      dateYaer: '', // 动态添加修改excel文件
      isDate: false
    };
  },

  created() {
    this.rulesForm.year = ((new Date()).getFullYear() - 1).toString();
    this.getProvince();
    const path = this.$route.path;
    this.queryReceiptList();
    this.getStatistics();
  },

  methods: {
    // 获取省数据
    async getProvince() {
      const api = this.$fetchApi.getCityList.api;
      const data = await this.$fetchData.fetchPost({ level: 'PROVINCE', parentId: '' }, api, 'json');
      this.provinceList = data.result;
    },
    centerDialogVisibles() {
       this.$router.push("/index");
    },
    async queryUserRank() {
      const api = this.$fetchApi.queryUserRank.api;
      const data = await this.$fetchData.fetchPost({}, api, "json");
      if (data.code === "200") {
        if (data.result.type === 0) {
          this.centerDialogVisible = true;
        } else {
          if (data.result.rank === 0) {
            this.centerDialogVisiblese = true;
          } else {
            return;
          }
        }
      }
    },
    async queryReceiptList() {
      const api = this.$fetchApi.queryReceiptList.api;
      const data = await this.$fetchData.fetchPost(this.param, api, 'json');
      if (data.code === '200') {
        this.backlog = data.result.total;
      }
    },
    /** 查询 */
    submit() {
      this.$refs.rulesForm.validate(vali => {
        if (vali) {
          this.getStatistics();
        } else {
          return false;
        }
      });
    },
    /** 获取统计数据 */
    async getStatistics() {
      const result = await this.$fetchData.fetchGet({
        registerProvinceId: this.rulesForm.registerProvinceId,
        year: this.rulesForm.year
      }, '/api/keeponrecord/backend/register/registerStatistics');
      if (result.code === '200') {
        this.dateYaer = this.rulesForm.year + new Date().getTime();
        this.filePath = result.message;
      } else {
        this.$message({ type: 'error', message: result.message });
      }
    },
    // 下载
    async downLoadFile() {
      this.$refs.rulesForm.validate(async (vali) => {
        if (vali) {
          const api = '/api/keeponrecord/backend/register/export';
          const params = {
            registerProvinceId: this.rulesForm.registerProvinceId,
            year: this.rulesForm.year*1
          };
          const data = await this.$fetchData.fetchPost(params, api, 'arraybuffer');
          
          downlodFile(data, '南繁备案.xlsx');
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
/deep/.el-year-table td.today .cell{
  color: #606266;
  font-weight: normal;
}
.filter-form {
  padding-top: 10px;
}
.iframe-demo{
  width: 100%;
  height: 600px;
  margin-bottom: 10px;
}

.nf-tabs {
  max-width: 1200px;
  min-height: 600px;
  display: flex;
  margin: 0 auto;

  .icon {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    background: none no-repeat center;
    background-size: 100%;
    margin-right: 4px;

    &-await {
      background-image: url("../../assets/images/officialDocument/icon_db.png");
    }

    &-processing {
      background-image: url("../../assets/images/officialDocument/icon_bw.png");
    }

    &-draft {
      background-image: url("../../assets/images/officialDocument/icon_nw.png");
    }
  }

  &-nav {
    min-width: 170px;
    max-width: 170px;
    margin-right: 10px;
    background: #ffffff;
    box-shadow: 2px 0px 3px 0px rgba(38, 90, 225, 0.15);

    &-label {
      display: block;
      height: 60px;
      line-height: 60px;
      text-align: center;
      font-weight: 700;
      color: #3380fe;

      &.is-active {
        background: url("../../assets/images/officialDocument/pic_tab_nav_bg.png")
          center no-repeat;
        background-size: 100%;
      }
    }
  }

  &-content {
    width: 100%;
  }
}
</style>
